#header{
    background: white;
    box-shadow: 0 2px 4px rgba(0,0,0,.1);
    transform: translateY(0);
    z-index: 100;
}
#header.transparent{
    background: transparent;
    box-shadow: none;
    transform: translateY(-1px);
    transition: all .3s;
}
#Calque_1{
    height: 55px;
}

.logo-v,.logo-b{
    transition: all .3s ease .1s;
}
#header.transparent .logo-v,#header.transparent .logo-b{
    fill: white;
}
#header.transparent .nav-item a{
    color: white;
}
#header.transparent .nav-item a.btn:hover {
    color: var(--color-primary);
}
@media screen and (max-width: 992px){
    section#presentation {
        margin-top: 0;
        margin-bottom: 0;
    }
    #header .ms-4 {
        margin-left: 0!important;
    }
    .nav-link{
        padding: 12px var(--bs-nav-link-padding-x);
        font-size: 16px;
        text-align: center;
    }
    #header{
        box-shadow: 0 2px 4px rgba(0,0,0,.1);
    }
    #Calque_1{
        height: 48px;
    }
}