@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600&display=swap');

*{
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
:root{
  --secondary:#f74104;
  --color-secondary:#00217C;
  --bs-blue: #00217C;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #00217C;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 13,110,253;
  --bs-secondary-rgb: 108,117,125;
  --bs-success-rgb: 25,135,84;
  --bs-info-rgb: 13,202,240;
  --bs-warning-rgb: 255,193,7;
  --bs-danger-rgb: 220,53,69;
  --bs-light-rgb: 248,249,250;
  --bs-dark-rgb: 33,37,41;
  --bs-primary-text-emphasis: #052c65;
  --bs-secondary-text-emphasis: #2b2f32;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #cfe2ff;
  --bs-secondary-bg-subtle: #e2e3e5;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #9ec5fe;
  --bs-secondary-border-subtle: #c4c8cb;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255,255,255;
  --bs-black-rgb: 0,0,0;
  --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue","Noto Sans","Liberation Sans",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-color-rgb: 33,37,41;
  --bs-body-bg: #fff;
  --bs-body-bg-rgb: 255,255,255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0,0,0;
  --bs-secondary-color: rgba(33, 37, 41, 0.75);
  --bs-secondary-color-rgb: 33,37,41;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233,236,239;
  --bs-tertiary-color: rgba(33, 37, 41, 0.5);
  --bs-tertiary-color-rgb: 33,37,41;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248,249,250;
  --bs-heading-color: inherit;
  --bs-link-color: #00217C;
  --bs-link-color-rgb: 13,110,253;
  --bs-link-decoration: underline;
  --bs-link-hover-color: #f74104;
  --bs-link-hover-color-rgb: 10,88,202;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(13, 110, 253, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
  --color-light:#383430;
  --color-bg-variant:#f5f5f5;
  --color-bg-variant2:#f1f1f1;
  --color-bg-variant3:#dedede;
  
  --color-gradient:rgb(225, 234, 238);
  --color-bg:#f8fbff;
  --color-bg-variant:#fffffffb;
  --color-text:#7a7a7a;
  --color-primary-text:#f3ece9;
  /* --color-primary:#ff3600; */
  --color-primary:#e33b04;
  /* --color-primary:#f74104; */
  /* --color-primary:#00217C; */
  --color-primary-variant:hsla(15, 97%, 45%, 0.598);
  --color-white:#fff;
  --color-dark-2: #00081d;
  --color-dark: #000d22;
  --transition: all 400ms cubic-bezier(0.35, 0.5, 0.65, 0.95);
  --container-width-lg:75%;
  --container-width-md:86%;
  --container-width-ms:90%;
  --shadow:rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  --shadow-sm:rgba(99, 99, 99, 0.2) 0px 2px 8px -1px;
  /* --shadow:5px 5px 6px -1px rgba(200, 200, 200, 0.2),
           -4px -5px 7px -1px rgba(245, 245, 245, 0.8); */

}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #f74104;
  --bs-btn-border-color: #00217C;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00195c;
  --bs-btn-hover-border-color: #00195c;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00195c;
  --bs-btn-active-border-color: #00195c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00217C;
  --bs-btn-disabled-border-color: #00217C;
}
/* .btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #00217C;
  --bs-btn-border-color: #00217C;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #00195c;
  --bs-btn-hover-border-color: #00195c;
  --bs-btn-focus-shadow-rgb: 49,132,253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #00195c;
  --bs-btn-active-border-color: #00195c;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #00217C;
  --bs-btn-disabled-border-color: #00217C;
} */
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.shadow{
box-shadow:0 0 6px 0 rgba(0,0,0,.15);
}
html{
  scroll-behavior: smooth;
  font-size: 16px;
}
::-webkit-scrollbar{
  display: none;
}

body {
  font-family: Inter;
  color: var(--color-text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.nav-link:focus, .nav-link:hover {
  color: var(--color-primary);
}

/* ============= GENRAL STYLES =============  */
.container{
  /* width:var(--container-width-lg); */
  margin: 0 auto;
}
h1,h2,h3,h4,h5,h6{
  font-weight: 600;
}
.bg__secondary{
  background-color: var(--bs-blue);
}
h1{
  font-size:2.5rem;
}
p{
  text-align: justify;
  line-height: 1.5;
}
section{padding-top: 6rem; padding-bottom: 6rem;}
@media(max-width:1280px){
  section{
    min-height: 100vh;
  }
}

section>h5,section>h2{
  text-align:center;
  color: var(--color-text);
}
section > h2{
  /* color: var(--color-primary); */
  font-weight: 400;
  margin-bottom: 3rem;
}
section > h2 span{
  /* color: var(--color-primary); */
  font-weight: 600;
  margin-bottom: 3rem;
}
.text-light{
  color: var(--color-light);
}
.text-primary{
  color: var(--color-primary)!important;
}
a{
  color: var(--color-primary);
  transition: var(--transition);
  text-decoration: none;
}

a:hover{
  color:var(--color-primary-variant) ;
}
.btn{
  width: max-content;
  display: inline-block;
  border: 1px solid transparent;
  color: var(--color-primary);
}
.btn.btn-2{
  padding-left:12px!important;
  padding-right:12px!important;
  padding-top:8px!important;
  padding-bottom:8px!important;
  border-radius: 30px;
}
.hidden{
  overflow: hidden;
}
.btn.btn-primary{
  background:var(--color-primary);
  color:var(--color-primary-text);
}
.invisible{
  display: none;
}
.btn:hover{
  background:var(--bs-body-bg)!important;
  color:var(--color-primary);
  border: 1px solid var(--color-primary);
}
img{
  display: block;
  width: 100%;
  object-fit: cover;
}
.text-primary{
  color: var(--color-primary)!important;
}
.text-justify{
  text-align: justify!important;
}
.text-center{
  text-align: center;
}
.text-secondary{
  color: var(--secondary)!important;
}
.bg__white{
  background-color: var(--color-white);
}
.bg__primary{
  background-color: var(--color-primary);
}
.bg__light{
  background-color: var(--color-bg);
}
/* ================== MEDIA QUERIES (MEDIUM DEVICES) ==================*/

@media screen and (max-width:1024px){
  .container{
    /* width: var(--container-width-md); */
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }
  section{
    margin-top: 6rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}
@media screen and (max-width:992px){
  html {
    font-size: 14px;
  }
  .sm{
    display: inline-block;
  }
  .no-sm{
    display: none;
  }

  .d-md-flex{
    display: flex;
  }
  .d-md-flex.justify-content-md-center{
    justify-content: center!important;
  }
}

/* ================== MEDIA QUERIES (SMALL DEVICES) ==================*/
@media screen and (max-width:600px){
  .container{
    width: calc(100vw - 30px);
  }
  section{
    margin-bottom: 0;
    margin-top: 0;
    min-height: max-content;
    /* margin-bottom: 1rem;
    margin-top: 2rem; */
  }
}