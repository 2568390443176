/* --bs-gray-800 */
footer .footer-top{
    background-color: var(--color-dark-2);
    padding-top: 80px;
    padding-bottom: 35px;
}

footer .nav-link{
    color: var(--color-light-2);
}

footer .footer-bottom{
    background-color: var(--color-dark);
    padding-top: 8px;
    padding-bottom: 8px;
}

footer .footer-bottom p{
    margin-bottom: 0;
}

footer .nav-link {
    display: inline-block;
    position: relative;
}
footer ul li a:hover::after {
    width: 100%;
    left: auto;
    right: 0;
}
footer ul li a.no-unlink:hover::after {
    width: 0;
    background: transparent;
}
footer ul li a::after {
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--color-primary);
    content: "";
    -webkit-transition: all ease .3s;
    -ms-transition: all ease .3s;
    transition: all ease .3s;
    z-index: 1;
}
@media(max-width:992px){
    footer .footer-bottom{
        padding-bottom: 1rem;
        /* padding-bottom: 6rem; */
    }
}
