#loading{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 6000;
    background-color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
}
#loading img{width: 90%;animation: loading-img 1.5s cubic-bezier(0.5, 0, 0.5, 1) both alternate infinite;}

.loading-shape{
    position: absolute;
    z-index: 7000;border-radius: 50%;
    border-top: 2px solid var(--primary);
    border-left: 2px solid var(--secondary);
    top: -34%;left: -13px;height: 220px;width: 220px;
    animation: loading-anime 1s cubic-bezier(0.5, 0, 0.5, 1) both infinite; 
}
@keyframes loading-anime {
    0%{transform: rotate(0);}
    100%{transform: rotate(180deg);}
}
@keyframes loading-img {
    0%{transform: scale(0.85);}
    100%{transform: scale(1);}
}
.loading-img{position:relative; width: 200px;height:200px}
#loading.hidden{animation-play-state:paused ;}
        